export default {
  heading: 'Damion, cursive',
  body: 'Montserrat, san-serif',
  monospace: 'Menlo, monospace',
  display: 'New Tegomin, cursive',
  googleFonts: [
    'Montserrat:100,200,300,400,500,600,700,800,900',
    'Playfair Display:400,500,600,700,800,900',
    'New Tegomin',
    'Damion',
  ],
  // customFamilies: [''],
  // customUrls: [''],
}
