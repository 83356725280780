export default {
  primary: {
    backgroundColor: 'transparent',
    color: 'dark',
    border: 'none',
    borderTop: 'solid 1px',
    borderBottom: 'solid 1px',
    transition: 'all ease-in-out 0.7s',
    backgroundSize: '100% 204%',
    backgroundColor: 'transparent',
    backgroundImage: 'linear-gradient(to bottom,rgba(0,0,0,0) 50%, #fbafdf 50%)',
    borderColor: 'primary',
    padding: '1rem 2rem',
    fontFamily: 'heading',
    fontWeight: 'normal',
    fontSize: '1.5rem',
    textTransform: 'capitalize',
    ':hover': {
      backgroundColor: 'transparent',
      backgroundPosition: '0% -98%',
      color: 'light',
      borderColor: '#fbafdf',
    },
  },
  secondary: {
    backgroundColor: 'transparent',
    padding: '1rem 0.5rem',
    color: 'primary',
    border: 'none',
    borderTop: 'solid 1px',
    borderBottom: 'solid 1px',
    borderColor: 'primary',
    fontWeight: 'bold',
    display: 'flex',
    fontSize: '0.85rem',
    ':hover': {
      opacity: '0.4',
      backgroundColor: 'transparent',
      color: 'grey',
    },
  },
}
