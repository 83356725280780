export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================
  navigation: {
    '.container': {
      position: 'static',
      color: 'dark',
      padding: ['0.25rem', '', '', '1rem'],
      borderBottom: '1px solid',
      '.logoLocationContainer': {
        position: 'static',
        display: ['', '', '', 'none'], // ! hidden as logo is in the hero
        // margin: '0rem auto 0rem 0rem',
        margin: '0rem',
        '.logo': {
          img: {
            maxHeight: ['60px', '60px', '60px', '60px'],
            filter: 'unset',
          },
        },
      },
    },
    '.containerScrolled': {
      '.logoLocationContainer': {
        display: ['', '', '', 'none'], // ! hidden as logo is in the hero
      },
    },
    '.smallNavMenu': {
      color: 'dark',
      margin: '0rem auto',
      '.navItem': {
        padding: ['', '', '', '1rem 1.5rem', '1rem 1.8rem', '1rem 3rem'],
      },
    },
    '.hamburger, .hamburgerOpen': {
      border: 'none',
      height: '30px',
      padding: '0rem',
    },
    '.navMenu': {
      top: '250vh',
      transform: 'rotate3d(1,1,1,-192deg)',
      right: '200vh',
    },
    '.navMenuOpen': {
      width: ['85%', '', '50%'],
      right: 'unset',
      left: '0rem',
      borderRight: 'solid 1px black',
      '.navItem': {
        a: {
          textTransform: 'capitalize',
        },
      },
    },

    '.navMenuLogo': {
      img: {
        maxHeight: '150px',
      },
    },
    '.navBlock': {
      display: 'none',
    },
  },

  footer: {
    borderTop: '1px solid',
    borderColor: 'primary',
    backgroundColor: '#f8f8f8',
    '.socialContainer, .contactDetails-container': {
      svg: {
        path: {
          fill: 'grey',
        },
      },
    },

    '.contactColumn': {
      '.logo': {
        marginBottom: '1.5rem',
      },
    },

    '.sectionHeading': {
      color: 'primary',
      fontWeight: '500',
      borderColor: 'secondary',
    },

    '.poweredBy .gonationLogo': {
      filter: 'brightness(0) invert(0.6)',
    },
    '.copyright': {
      backgroundColor: 'transparent',
      color: 'black',
    },
    '.multiButtonContainer': {
      border: 'none',
      flexDirection: ['', '', '', 'column'],
      a: {
        // variant: 'buttons.primary',
        width: ['', '', '', '100%'],
        border: 'none !important',
        fontSize: '0.75rem',
        fontFamily: 'display',
        margin: '0rem',
        padding: '0.5rem 0rem',
      },
    },
  },

  ctaWidget: {
    display: 'none',
    a: {
      backgroundColor: 'secondary',
    },
  },

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: ['2rem', '2.5rem', '3rem', '4rem'],
    color: 'primary',
    fontWeight: '400',
    whiteSpace: 'pre-wrap',
    textTransform: 'capitalize',
    marginBottom: '2rem',
  },
  subtitle: {
    color: 'secondary',
    fontWeight: 'bold',
    textTransform: 'initial',
    // order: '1',
    fontFamily: 'body',
    fontSize: '1.25rem',
    textTransform: 'uppercase',
    borderBottom: '2px solid',
    paddingBottom: '0.5rem',
    width: 'fit-content',
    marginBottom: '1.5rem',
  },
  text: {
    lineHeight: '1.9',
    marginBottom: '1rem',
    // order: '3',
  },

  sideBySide: {
    marginBottom: '2rem',
    padding: '1rem',
    '.content': {
      width: ['', '', '45%'],
      padding: ['2rem', '', '', '1.5rem 3.5rem', '2.5rem 4.5rem'],
      // order: '2',
      margin: ['', '', '1rem', '2rem'],
      '.title': {
        variant: 'customVariants.title',
      },
      '.subtitle': {
        variant: 'customVariants.subtitle',
      },

      '.text': {
        variant: 'customVariants.text',
      },
      a: {
        '.ctaButton': {
          variant: 'buttons.primary',
        },
        order: '4',
      },
    },
    '.lazyload-wrapper': {
      width: ['', '', '55%'],
      borderRadius: '100px',
      overflow: 'hidden',
      '::before': {
        background: 'unset',
      },
      img: {
        // height: ['', '', '', '65vh'],
      },
    },
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    height: ['', '', '', '80vh'],
    justifyContent: 'flex-start',
    alignItems: ['flex-start', '', '', 'center'],
    marginBottom: ['0rem', '', '1rem', '5rem'],
    overflow: 'hidden',
    '.logoHero': {
      display: ['none', '', '', 'block'],
    },
    '.slick-slider': {
      width: ['100%', '100%', '100%', '60%'],
      order: ['', '', '', '2'],
      height: ['', '', '', '75vh'],
      '.slick-list': {
        borderRadius: ['', '', '', '150px 0px 0px 300px'],
      },
      '.slick-prev, .slick-next': {
        color: 'white',
        bottom: '0rem',
        backgroundColor: 'primary',
        height: '30px',
        borderRadius: '100px',
        padding: '5px',
      },
      '.slick-prev': {
        left: '1rem',
      },
      '.slick-next': {
        left: '3rem',
      },
      '.slick-dots': {
        left: '1rem',
        right: 'unset',
        display: 'none',
      },
      '.slick-dots li button:before': {
        color: 'white',
        display: 'none',
      },
      '.slick-slide img': {
        height: ['', '', '', '75vh'],
        // objectFit: 'contain',
      },
    },
    '.hero_content_container': {
      // marginBottom: ['', '', '', '7rem'],
      padding: ['2rem', '', '', '4.5rem', '5.5rem'],
      '.title': {
        variant: 'customVariants.title',
        fontFamily: 'display',
        fontSize: ['1.25rem', '1.5rem', '2rem', '2.5rem'],
      },
      '.subtitle': {
        variant: 'customVariants.subtitle',
      },

      '.text': {
        variant: 'customVariants.text',
      },
      a: {
        order: '4',
        alignSelf: 'unset',
        '.ctaButton': {
          variant: 'buttons.primary',
        },
      },
    },
  },

  homepageHeroShout: {
    display: 'none',
  },

  homepageShout: {
    maxHeight: ['', '', '50vh'],
    margin: '1rem',
    width: 'calc(100% - 2rem)',
    '.imageContainer': {
      // order: ['2', ''],
      borderRadius: '100px',
      overflow: 'hidden',
    },
    '.content': {
      padding: ['1rem', '', '', '4rem', '5rem'],
      alignItems: 'flex-start',
      textAlign: 'left',
      order: ['1', ''],
    },
    '.title': {
      variant: 'customVariants.title',
      textAlign: 'left',
      margin: '1rem 0rem',
      // display: 'none',
      fontWeight: 'normal',
      borderBottom: '4px double',
      borderColor: 'secondary',
      width: 'fit-content',
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      textAlign: 'left',
      margin: '1rem 0rem',
    },
    '.text': {
      variant: 'customVariants.text',
      textAlign: 'left',
      margin: '1rem 0rem 2rem',
      maxWidth: 'unset',
    },
    '.date': {
      textAlign: 'left',
      margin: '1rem 0rem',
      color: 'primary',
      order: '2',
    },
    '.shoutCTABtns': {
      order: '5',
      '.shoutCTA': {
        variant: 'buttons.primary',
        padding: '1rem',
        fontSize: '1rem',
      },
    },
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide',
  },

  homepageGallery: {
    variant: 'customVariants.sideBySide',
  },

  homepageMenu: {
    variant: 'customVariants.sideBySide',
  },
  homepageEvents: {
    variant: 'customVariants.sideBySide',
  },

  homepageQuote: {
    padding: ['6rem 1rem', '', '10rem 1rem'],
    backgroundImage: 'none',
    '.title': {
      variant: 'customVariants.title',
      fontFamily: 'display',
      fontSize: ['1.75rem', '2.25rem', '2.5rem', '2.75rem'],
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
    },

    '.text': {
      variant: 'customVariants.text',
    },

    '.ctaButton': {
      variant: 'buttons.primary',
      marginTop: '2rem',
    },
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    '.title': {
      fontSize: '3.5rem',
      color: 'primary',
      padding: '1rem',
      borderBottom: '2px solid',
      borderColor: 'secondary',
    },
    '.subtitle': {
      fontSize: '1.75rem',
      opacity: '0.8',
    },
  },

  // ? ========================
  // ? ====  Menu page  ====
  // ? ========================

  menu: {
    maxWidth: 'unset',
    '.menuSectionTitle': {
      color: 'secondary',
      fontSize: '3rem',
      borderBottom: '4px dotted',
      borderColor: 'primary',
    },
    '.menuSectionDescription': {
      color: 'black',
      fontFamily: 'display',
    },
    '.allInContainerWrapper': {
      maxWidth: '767px',
      margin: '0 auto',
    },
    '.menuItemInnerContainer': {},
    '.menuItemImageContainer': {},
    '.menuItemName': {
      '::before': {
        content: "'☮'",
        color: 'secondary',
        marginRight: '0.5rem',
        fontSize: '2rem',
      },
      color: 'primary',
      fontFamily: 'display',
      textTransform: 'uppercase',
      marginBottom: '0rem',
    },
    '.menuItemContentContainer': {
      margin: '0rem',
    },
    '.dropdownContainer': {
      display: 'none',
    },
    '.section-cell': {},
    '.cellImageContainer': {},
    '.menuCell, .cellImage': {},
    '.menuCell': {},
    '.cellName': {},
    '.menuItemContainerImgActive': {},
    '.backToMenuBtn': {},
  },

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    // '.albumsCTA ': {
    //   variant: 'buttons.secondary',
    // },
    '.albumsContainer': {
      display: 'none',
    },
    '.albumTitle': {
      variant: 'buttons.secondary',
      backgroundColor: 'transparent',
      color: 'black',
    },
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.eventItemImage': {
      minHeight: 'unset',
    },
    '.modalEventName': {
      color: 'black',
    },
    '.eventDays, .eventDate': {
      color: 'black',
    },
    '.eventLinkButton': {
      display: 'none',
    },
    '.eventCTABtns': {
      a: {
        backgroundColor: 'black',
        color: 'white',
        fontSize: '0.9rem',
        ':hover': {
          opacity: '0.5',
        },
      },
    },
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    padding: '4rem 1rem',
    '.title': {
      display: 'flex',
      flexDirection: 'column',
      fontSize: '2.5rem',
      color: 'primary',
      fontWeight: '500',
      whiteSpace: 'pre-wrap',
      borderBottom: '2px solid',
      borderColor: 'secondary',
      marginBottom: '2rem',
    },
    '.subtitle': {
      textTransform: 'initial',
      fontSize: '1.5rem',

      opacity: '0.8',
      marginBottom: '4rem',
    },
    form: {
      input: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid grey',
      },

      textarea: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid grey',
      },
      '.submitBtn': {
        variant: 'buttons.primary',
        // borderRadius: '0px',
      },
    },
  },

  locationMap: {
    order: '3',
    backgroundColor: '#f5f5f5',
    h3: {
      textTransform: 'uppercase',
      borderBottom: 'solid 2px',
      borderColor: 'secondary',
    },

    '.dayofWeekText': {
      margin: '0 auto 0 0',
    },
  },

  // ? ========================
  // ? ====  Press Menu  ====
  // ? ========================

  pressMenu: {
    backgroundColor: 'background',
    padding: ['1rem', '', '2rem'],
    '.boxesHeading': {
      color: 'dark',
      margin: '2rem 1rem',
      padding: '1rem',
      fontSize: ['2rem', '', '4rem'],
    },
    '.textContent': {
      backgroundColor: '#dbdbcf',
    },
    '.box': {
      width: ['100%', 'calc(50% - 1rem)', 'calc(33.3% - 1rem)', 'calc(25% - 1rem)'],
    },
  },

  pressPage: {},

  // ? ========================
  // ? ====  Team Menu  ====
  // ? ========================

  teamMenu: {},

  teamPage: {},

  // ? ========================
  // ? ====  blog Menu  ====
  // ? ========================

  blogMenu: {},

  blogPage: {},
}
