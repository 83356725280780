export default {
  pageHeroV1: {
    container: {
      height: '50vh',
      width: '100%',
      alignItems: 'flex-end',
      justifyContent: 'flex-start',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      borderBottom: '1px solid',
    },

    content: {
      flexDirection: 'column',
      color: 'white',
      textAlign: 'center',
      margin: '1rem',
      h1: {
        fontSize: ['1.75rem', '', '2.5rem'],
        marginBottom: 1,
        textTransform: 'uppercase',
        color: 'white',
        borderBottom: 'solid 5px',
        borderColor: 'secondary',
        borderRadius: '24px',
        padding: '2rem',
        backgroundColor: 'primary',
      },
    },
  },
}
